<template>
    <div class="add">
        <!-- 新增时间配置 -->
        <el-dialog title="详情" :visible.sync="dialogVisible" width="30%">
            <!-- <span>这是一段信息</span> -->
            <div class="container">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" size="small">
                    <el-form-item label="咨询师" prop="userId" v-if="userRole === 1">
                        <br />
                        <el-select v-model="ruleForm.userId" placeholder="请选择">
                            <el-option :label="item.nickname" :value="item.id" v-for="item in TeacherData"
                                :key="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="可预约组织机构" prop="deptIdList">
                        <br />
                        <el-cascader :options="options" placeholder="请选择所属组织机构" :show-all-levels="false"
                            :props="{ value: 'id', label: 'name', checkStrictly: true, emitPath: false, multiple: true, }"
                            v-model="ruleForm.deptIdList"></el-cascader>
                    </el-form-item>
                    <el-form-item label="咨询地点" prop="address">
                        <el-input placeholder="请输入内容" v-model="ruleForm.address" clearable>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="生效期限">
                        <br />
                        <div class="item">
                            <el-form-item prop="startDate">
                                <el-date-picker v-model="ruleForm.startDate" type="date" placeholder="开始日期"
                                    value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                            <span> 至 </span>
                            <el-form-item prop="endDate">
                                <el-date-picker v-model="ruleForm.endDate" type="date" placeholder="结束日期"
                                    value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                        </div>
                        <!-- <el-input v-model="ruleForm.name"></el-input> -->
                    </el-form-item>
                    <el-form-item label="选择日期" prop="date">
                        <br />
                        <el-checkbox-group v-model="ruleForm.date">
                            <el-checkbox label="周日"></el-checkbox>
                            <el-checkbox label="周一"></el-checkbox>
                            <el-checkbox label="周二"></el-checkbox>
                            <el-checkbox label="周三"></el-checkbox>
                            <el-checkbox label="周四"></el-checkbox>
                            <el-checkbox label="周五"></el-checkbox>
                            <el-checkbox label="周六"></el-checkbox>
                        </el-checkbox-group>
                        <!-- <el-input v-model="ruleForm.name"></el-input> -->
                    </el-form-item>
                    <el-form-item label="时间段" 
                    >
                        <br />
                        <el-select v-model="ruleForm.type" placeholder="请选择" @change="changeType">
                            <el-option label="全天" :value="1">
                            </el-option>
                            <el-option label="上午" :value="2">
                            </el-option>
                            <el-option label="下午" :value="3">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="开始时间" prop="startTime">
                        <br />
                        <el-time-select v-if="ruleForm.type === 1" v-model="ruleForm.startTime" :picker-options="{
                            start: '00:00',
                            step: '00:30',
                            end: '24:00'
                        }" placeholder="选择时间">
                        </el-time-select>
                        <el-time-select v-if="ruleForm.type === 2" v-model="ruleForm.startTime" :picker-options="{
                            start: '00:00',
                            step: '00:30',
                            end: '12:00'
                        }" placeholder="选择时间">
                        </el-time-select>
                        <el-time-select v-if="ruleForm.type === 3" v-model="ruleForm.startTime" :picker-options="{
                            start: '12:00',
                            step: '00:30',
                            end: '24:00'
                        }" placeholder="选择时间">
                        </el-time-select>
                    </el-form-item>
                    <el-form-item label="结束时间" prop="endTime">
                        <br />
                        <el-time-select v-if="ruleForm.type === 1" v-model="ruleForm.endTime" :picker-options="{
                            start: '00:00',
                            step: '00:30',
                            end: '24:00',
                            minTime: ruleForm.startTime
                        }" placeholder="选择时间">
                        </el-time-select>
                        <el-time-select v-if="ruleForm.type === 2" v-model="ruleForm.endTime" :picker-options="{
                            start: '00:00',
                            step: '00:30',
                            end: '12:00',
                            minTime: ruleForm.startTime
                        }" placeholder="选择时间">
                        </el-time-select>
                        <el-time-select v-if="ruleForm.type === 3" v-model="ruleForm.endTime" :picker-options="{
                            start: '12:00',
                            step: '00:30',
                            end: '24:00',
                            minTime: ruleForm.startTime
                        }" placeholder="选择时间">
                        </el-time-select>
                    </el-form-item>
                    <el-form-item label="持续时长" prop="duration">
                        <el-select v-model="ruleForm.duration" placeholder="请选择">
                            <el-option label="30" :value="30">
                            </el-option>
                            <el-option label="45" :value="45">
                            </el-option>
                            <el-option label="60" :value="60">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="可预约人数">
                        <br />
                        <span>{{userCount}}</span>
                    </el-form-item>
                    <el-form-item label="状态">
                        <br />
                        <el-select v-model="ruleForm.status" placeholder="请选择">
                            <el-option label="禁用" :value="false">
                            </el-option>
                            <el-option label="启用" :value="true">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')" size="small">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getTeachersList } from '@/api/dangerWarning/crisisIntervention'
import { updateTime, detailTime } from '@/api/consultation/consultingTime'
import { getOrganizationList } from '@/api/system/organization'
export default {

    data() {
        return {
            dialogVisible: false,
            TeacherData: [], // 咨询师用户
            options: null, // 组织机构数据
            ruleForm: {
                deptIdList: null, 
                userId: null,
                address: null,
                startDate: null,
                endDate: null,
                date: [],
                startTime: null,
                endTime: null,
                duration: null,
                status: false
            },
            timeId: null,
            rules: {
                userId: [
                    { required: true, message: '请选择咨询师', trigger: 'blur' },
                ],
                address: [
                    { required: true, message: '请选输入询地点', trigger: 'blur' },
                ],
                startDate: [
                    { required: true, message: '请选择开始日期', trigger: 'blur' },
                ],
                endDate: [
                    { required: true, message: '请选择结束日期', trigger: 'blur' },
                ],
                date: [
                    { required: true, message: '请选择日期', trigger: 'blur' },
                ],
                deptIdList: [
                    { required: true, message: '请选择可预约组织机构', trigger: 'blur' },
                ],
                startTime: [
                    { required: true, message: '请选择开始时间', trigger: 'blur' },
                ],
                endTime: [
                    { required: true, message: '请选择结束时间', trigger: 'blur' },
                ],
                duration: [
                    { required: true, message: '请选择持续时长', trigger: 'blur' },
                ]
            },
            userRole: 4, // 1.管理员 4.咨询师
        }
    },
    methods: {
        open(val) {
            // 1.管理员 4.咨询师
            this.userRole = (JSON.parse(sessionStorage.getItem('userInfo'))).roleId
            // console.log(this.userRole)
            if(this.userRole === 4) {
                this.ruleForm.userId = (JSON.parse(sessionStorage.getItem('userInfo'))).id;
            }
            this.timeId = val;
            this.getAllList();
            this.getTeacherList();
            this.getDetails(val)
            
            this.dialogVisible = true;
        },
        // 获取组织机构列表
        async getAllList() {
            const res = await getOrganizationList()
            console.log(res);
            if (res.code === 200) {
                this.options = res.data;
                // this.options.push({ id: 0, name: '无(第一级)' })
                // this.deleteNullChildren(this.options)
            }
        },
        // 获取咨询师列表
        async getTeacherList() {
            const res = await getTeachersList();
            console.log(res);
            if (res.code === 200) {
                this.TeacherData = res.data;
            }
        },
        // 获取咨询时间详情
        async getDetails(val) {
            const res = await detailTime(val);
            console.log(res);
            if (res.code === 200) {
                let info = res.data;
                info.date = [...info.date];
                let date = [];
                let date2 = [];
                if (info.date.length > 0) {
                    date = info.date.filter(item => {
                        return item !== ','
                    })
                  date2 = date.map(item => {
                        if (item === '1') {
                            return item = '周一'
                        } else if (item === '2') {
                            return item = '周二'
                        } else if (item === '3') {
                            return item = '周三'
                        } else if (item === '4') {
                            return item = '周四'
                        } else if (item === '5') {
                            return item = '周五'
                        } else if (item === '6') {
                            return item = '周六'
                        } else if (item === '7') {
                            return item = '周日'
                        }
                    })
                }
                
                // console.log(date2)
                info.date = date2;
                this.ruleForm = info;
                // console.log(info)
            }
        },
         // 修改时间段后，对应的时间也应该改变
         changeType() {
            this.ruleForm.startTime = null;
            this.ruleForm.endTime = null;
        },
        // 点击确定
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let date1 = this.ruleForm.startDate;
                    let date2 = this.ruleForm.endDate;
                    let dates1 = date1.replace(/\-/g, '/')
                    let dates2 = date2.replace(/\-/g, '/')
                    // 判断开始日期是否大于结束日期
                    if (Date.parse(dates1) < Date.parse(dates2)) {
                        // 在判断开始时间与结束时间
                        let Time1 = this.ruleForm.startTime.replace(/\:/g, '');
                        let Time2 = this.ruleForm.endTime.replace(/\:/g, '');
                        if (Number(Time1) < Number(Time2)) {
                            let info = { ...this.ruleForm,id : this.timeId }
                            delete info.userCount;
                            if (info.date.length > 0) {
                                info.date = info.date.map(item => {
                                    if (item === '周一') {
                                        return item = 1
                                    } else if (item === '周二') {
                                        return item = 2
                                    } else if (item === '周三') {
                                        return item = 3
                                    } else if (item === '周四') {
                                        return item = 4
                                    } else if (item === '周五') {
                                        return item = 5
                                    } else if (item === '周六') {
                                        return item = 6
                                    } else if (item === '周日') {
                                        return item = 7
                                    }
                                })
                            }

                            info.date = info.date.toString();
                            info.startTime = info.startTime + ":00"
                            info.endTime = info.endTime + ":00"
                            console.log(info)
                            const res = await updateTime(JSON.stringify(info));
                            console.log(res);
                            if (res.code === 200) {
                                this.$message({
                                    showClose: true,
                                    message: '修改成功！',
                                    type: 'success'
                                });
                                this.$emit('getList')
                                this.dialogVisible = false;
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: '修改失败！' + res.msg,
                                    type: 'error'
                                });
                            }
                        } else {
                            this.$message({
                                showClose: true,
                                message: '开始时间不能大于结束时间'
                            });
                        }

                    } else {
                        this.$message({
                            showClose: true,
                            message: '开始日期不能大于结束日期'
                        });
                    }

                    // alert('submit!');
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    },
    computed: {
        // 计算可预约人数
        userCount() {
            if(this.ruleForm.endTime && this.ruleForm.startTime && this.ruleForm.duration) {
                // let time1 = Number(this.ruleForm.endTime.replace(':',''));
                // let time2 = Number(this.ruleForm.startTime.replace(':',''))
                let time1 = '2022-12-12 ' + this.ruleForm.endTime + ':00'
                let t1 = new Date(time1)
                let time2 = '2022-12-12 ' + this.ruleForm.startTime + ':00'
                let t2 = new Date(time2);
                let totalTime = parseInt(t1 - t2) / 1000 / 60
                // return (parseInt(t1 - t2) / 1000 / 60);
                return Math.floor(totalTime / this.ruleForm.duration)

            } else {
                return 0
            }
           
        }
    }
}
</script>

<style lang="scss" scoped>
.add {
    .container {
        ::v-deep .el-checkbox {
            margin-right: 5px !important;
        }

        .el-date-editor.el-input,
        .el-date-editor.el-input__inner,
        .el-select {
            width: 100% !important;
        }

        .item {
            width: 100%;
            display: flex;

            span {
                display: inline-block;
                margin: 0 20px;
            }

        }
    }
    ::v-deep.el-dialog {
        margin-top: 10vh !important;
        margin-bottom: 0px !important;
        height: 75%;
        overflow-y: scroll;
        // position: relative;
    }
    .el-cascader{
        width: 100%;
    }
}
</style>