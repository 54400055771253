<template>
  <div class="index">
    <!-- 咨询时间 -->
    <div class="searchBtn">
      <div>
        <el-select v-model="searchInfo.progress" size="small" clearable placeholder="请选择状态">
          <el-option label="未开始" :value="1">
          </el-option>
          <el-option label="进行中" :value="2">
          </el-option>
          <el-option label="已结束" :value="3">
          </el-option>
        </el-select>
        <el-date-picker clearable size="small" v-model="searchInfo.startTime" type="date" placeholder="开始时间"
          value-format="yyyy-MM-dd 00:00:00" style="margin-right:10px" format="yyyy-MM-dd">
        </el-date-picker>
        <span>至</span>
        <el-date-picker clearable size="small" v-model="searchInfo.endTime" type="date" placeholder="结束时间"
          format="yyyy-MM-dd" value-format="yyyy-MM-dd 23:59:59">
        </el-date-picker>
      </div>
      <el-button type="primary" size="small" @click="search">搜索</el-button>
      <el-button type="primary" size="small" @click="addTime">新增</el-button>
      <el-button type="primary" size="small" @click="deleteAsync">删除</el-button>
    </div>
    <div class="table">
      <template>
        <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection">
          </el-table-column>
          <el-table-column prop="id" label="序号">
          </el-table-column>
          <el-table-column prop="consultName" label="咨询师">
          </el-table-column>
          <el-table-column prop="startTime" label="开始时间">
          </el-table-column>
          <el-table-column prop="endTime" label="结束时间">
          </el-table-column>
          <el-table-column label="起始日期">
            <template slot-scope="scope">
              <span>{{ scope.row.startDate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="结束日期">
            <template slot-scope="scope">
              <span>{{ scope.row.endDate }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建日期">
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <span v-if="(scope.row.progress === 1)">未开始</span>
              <span v-if="(scope.row.progress === 2)">进行中</span>
              <span v-if="(scope.row.progress === 3)">已过期</span>
            </template>
          </el-table-column>
          <el-table-column label="是否启用">
            <template slot-scope="scope">
              <span v-if="(scope.row.status === false)">禁用</span>
              <span v-if="(scope.row.status === true)">启用</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="change(scope.row.id)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <div class="block">
      <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
        layout="total, prev, pager, next, jumper" :total="paging.total">
      </el-pagination>
    </div>
    <AddVue ref="add" @getList="getList"></AddVue>
    <ChangeVue ref="change" @getList="getList"></ChangeVue>
  </div>
</template>

<script>
import AddVue from './Add.vue';
import ChangeVue from './Change.vue';
import { getTimeList, deleteTime } from '@/api/consultation/consultingTime'
export default {
  components: { AddVue, ChangeVue },
  data() {
    return {
      searchInfo: {
        startTime: null,
        endTime: null,
        progress: null
      },
      tableData: [],
      selectList: [], // 用户点击选择的数据
      paging: {
        p: 1,
        ps: 20,
        total: 0
      }
    }
  },
  methods: {
    // 用户点击选择
    handleSelectionChange(val) {
      // this.multipleSelection = val;
      this.selectList = val;
    },
    // 获取咨询时间列表
    async getList() {
      let info = { p: this.paging.p, ps: this.paging.ps, ...this.searchInfo }
      const res = await getTimeList(JSON.stringify(info));
      console.log(res);
      if (res.code === 200) {
        this.tableData = res.data.records;
        this.paging.total = res.data.total;
      }
    },
    // 点击搜索
    search() {
      this.paging.p = 1;
      this.getList();
    },
    // 点击分页
    handleCurrentChange(val) {
      this.paging.p = val;
      this.getList();
    },
    // 删除咨询时间
    deleteAsync() {
      // console.log(this.selectList)
      if (this.selectList.length > 0) {
        this.$confirm('确定删除该数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let list = [];
          this.selectList.forEach(item => {
            list.push(item.id)
          })
          // console.log(list)
          const res = await deleteTime(JSON.stringify(list));
          console.log(res);
          if (res.code === 200) {
            this.$message({
              showClose: true,
              message: '删除成功',
              type: 'success'
            });
            this.getList();
          } else {
            this.$message({
              type: 'error',
              showClose: true,
              message: '删除失败' + res.msg
            });
            this.getList();
          }

        }).catch(() => {
          this.$message({
            type: 'info',
            showClose: true,
            message: '已取消删除'
          });
        });


      } else {
        this.$message({
          showClose: true,
          message: '请选择需要删除的数据！'
        });

      }
    },
    // 新增时间
    addTime() {
      this.$refs.add.open();
    },
    // 查看详情
    change(val) {
      this.$refs.change.open(val);
    }
  },
  created() {
    this.getList();
  }
}

</script>

<style lang="scss" scoped>
.index {
  padding: 20px;

  .table {
    margin-top: 20px;
  }
}
</style>