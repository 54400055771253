import request from '@/utils/request'
// 预约时间

// 获取咨询时间列表
export function getTimeList(data) {
    return request({
      url: `/reserve/consultDate/page`,
      method: 'post',
      data
    });
}
// 新增时间设置
export function addTime(data) {
    return request({
      url: `/reserve/consultDate`,
      method: 'post',
      data
    });
}
// 修改时间设置
export function updateTime(data) {
  return request({
    url: `/reserve/consultDate`,
    method: 'put',
    data
  });
}
// 删除咨询时间
export function deleteTime(data) {
  return request({
    url: `/reserve/consultDate`,
    method: 'delete',
    data
  });
}
// 获取咨询时间详情
export function detailTime(id,params) {
  return request({
    url: `/reserve/consultDate/${id}`,
    method: 'get',
    params
  });
}